export const AUTH = "AUTH/auth";
export const LOGIN = "AUTH/login";
export const MFA_LOGIN = "AUTH/mfa_login";
export const SIGN_UP = "AUTH/sign_up";
export const LOGOUT = "AUTH/logout";
export const USER_PROFILE = "AUTH/user_profile";
export const REGISTER = "AUTH/register";
export const GET_PROFILE = "AUTH/get_profile";
export const GET_RPTAX_API = "AUTH/get_rptax";
export const UPDATE_PROFILE = "AUTH/update_profile";
export const UPDATE_PASSWORD = "AUTH/update_password";
export const ACTIVATE_ACCOUNT = "AUTH/activate_account";
export const FORGOT_PASSWORD = "AUTH/forgot_password";
export const RESET_PASSWORD = "AUTH/reset_password";
export const RESEND_OTP = "AUTH/resend_otp";
export const FORGOT_PASSWORD_VALIDATE = "AUTH/forgot_password_validate";
export const RESEND_FORGOT_PASSWORD_VALIDATE = "AUTH/resend_forgot_password_validate";
export const RESET_PASSWORD_OTP = "AUTH/reset_password_otp";
export const UPDATE_PIN = 'CITIZEN/update_pin';

export const GENERATE_2FA = 'AUTH/generate_2fa';
export const ENABLE_2FA = 'AUTH/enable_2fa';
export const DISABLE_2FA = 'AUTH/disable_2fa';
export const EMAIL_2FA = 'AUTH/email_2fa';
export const PREFERRED_MFA = 'AUTH/preferred_mfa'
export const ENABLE_MOBILE_AUTHENTICATOR = 'AUTH/enable_mobile_authenticator'

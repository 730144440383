import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

const PinInput = ({ onChange }) => {
  const [value, setValue] = useState(["", "", "", "", "", ""]);
  const [eye, setEye] = useState(false);
  const inputRefs = useRef([]);
  inputRefs.current = [];

  const setInputRef = (element, index) => {
    if (element && !inputRefs.current[index]) {
      inputRefs.current[index] = element;
    }
  };

  const onChangeValue = (val) => (e) => {
    e.preventDefault();

    const updatedValue = [...value];
    const firstEmptyIndex = updatedValue.findIndex((v) => v === "");
    if (firstEmptyIndex !== -1) {
      updatedValue[firstEmptyIndex] = val;
      setValue(updatedValue);
      onChange(updatedValue.join(""));

      if (inputRefs.current[firstEmptyIndex + 1]) {
        inputRefs.current[firstEmptyIndex + 1].focus();
      }
    }
  };

  const onClearLast = (e) => {
    e.preventDefault();
    const updatedValue = [...value];
    const lastFilledIndex = updatedValue
      .map((v, i) => (v !== "" ? i : -1))
      .filter((i) => i !== -1)
      .pop();
    if (lastFilledIndex !== undefined) {
      updatedValue[lastFilledIndex] = "";
      setValue(updatedValue);
      onChange(updatedValue.join(""));
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();
      const updatedValue = [...value];
      updatedValue[index] = "";
      setValue(updatedValue);
      onChange(updatedValue.join(""));

      if (e.key === "Backspace" && index > 0) {
        inputRefs.current[index - 1].focus();
      }

      if (e.key === "Delete" && index < value.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const toggelEye = () => {
    setEye(!eye);
  };

  const onClear = (e) => {
    e.preventDefault();
    setValue(["", "", "", "", "", ""]);
    onChange("");
  };

  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData("Text");
    if (pastedValue.length <= 6) {
      setValue(pastedValue.split(""));
      onChange(pastedValue);
    }
  };

  const handleInputChange = (index, e) => {
    const newValue = e.target.value;
    if (/[^0-9]/.test(newValue)) return;

    const updatedValue = [...value];
    updatedValue[index] = e.target.value;
    setValue(updatedValue);
    onChange(updatedValue.join(""));

    if (index < value.length - 1 && newValue !== "") {
      inputRefs.current[index + 1].focus();
    }
  };

  const render = (val) => {
    if (eye) return val;
    return val ? '*' : '';
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className="col text-center px-2 pt-4 pb-2 d-flex justify-content-between align-items-center">
          {Array.from({ length: 6 }, (_, index) => (
            <div className="d-flex justify-content-center font-weight-bold mfa-otp-underline" key={index}>
              <input
                type="text"
                maxLength={1}
                value={render(value[index])}
                onChange={(e) => handleInputChange(index, e)}
                onPaste={handlePaste}
                onKeyDown={(e) => handleKeyDown(index, e)}
                className="cms-text-primary text-center"
                style={{ fontSize: 24, width: "40px", border: "none", outline: "none"}}
                ref={(el) => setInputRef(el, index)}
              />
            </div>
          ))}

          <button
            onClick={toggelEye}
            type="button"
            className="btn btn-primary btn-sm cms-bg-color-primary">
            {eye ? <i className="far fa-eye-slash" /> : <i className="far fa-eye" />}
          </button>
        </div>
      </div>
      <div className="row">
        {[7, 8, 9].map((num) => (
          <div key={num} className="col text-center px-2 py-2">
            <button
              onClick={onChangeValue(num)}
              type="button"
              className="btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold">
              {num}
            </button>
          </div>
        ))}
      </div>

      <div className="row">
        {[4, 5, 6].map((num) => (
          <div key={num} className="col text-center px-2 py-2">
            <button
              onClick={onChangeValue(num)}
              type="button"
              className="btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold">
              {num}
            </button>
          </div>
        ))}
      </div>

      <div className="row">
        {[1, 2, 3].map((num) => (
          <div key={num} className="col text-center px-2 py-2">
            <button
              onClick={onChangeValue(num)}
              type="button"
              className="btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold">
              {num}
            </button>
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col text-center px-2 py-2">
          <button
            onClick={onClear}
            type="button"
            className="btn btl-lg btn-outline-danger w-100 h-100">
            Reset
          </button>
        </div>
        <div className="col text-center px-2 py-2">
          <button
            onClick={onChangeValue("0")}
            type="button"
            className="btn btl-lg btn-outline-primary w-100 h-100 font-weight-bold">
            0
          </button>
        </div>
        <div className="col text-center px-2 py-2">
          <button
            onClick={onClearLast}
            type="button"
            className="btn btl-lg btn-outline-danger w-100 h-100">
            {"<"}
          </button>
        </div>
      </div>
    </div>
  );
};

PinInput.defaultProps = {};

PinInput.propTypes = {
  onChange: PropTypes.instanceOf(Function).isRequired,
};

export default PinInput;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import CmsContext from "../../../CmsContext";
import Version from "./Version";

function Footer() {
  const cmsContext = useContext(CmsContext);
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");
  const CMS_LOGO = cmsContext.findByType("LOGOS");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");
  const cmsLogo = _.get(CMS_LOGO, "MAIN_LOGO.cdnUrl");

  // const handleOnOpen = e => {
  //   e.preventDefault();
  //   window.open("/dummy", '_blank', 'location=yes');
  // };

  // const handleOnOpen = e => {
  //   e.preventDefault();
  //   window.open("/dummy", '_blank', 'location=yes');
  // };

  // const handleOnOpen = e => {
  //   e.preventDefault();
  //   window.open("/dummy", '_blank', 'location=yes');
  // };

  return (
    <footer className="footer py-5 d-print-none">
      <div
        className="container"
        style={{ maxWidth: "1400px", backgroundCorlor: "white" }}
      >
        <div className="row my-4">
          <div className="col-12 col-md-4">
            <div className="logo-container">
              <img src={cmsLogo} alt="" className="icon-logo" />
              <br />
              <Version />
            </div>
          </div>
          <div className="col-12 col-md-8 text-right">
            <ul className="navbar-footer list-inline">
              {/* <li className="list-inline-item px-3">
                <button
                  type="button"
                  onClick={handleOnOpen}
                >
                  Click Me Right now
                </button>
              </li> */}
              <li className="list-inline-item px-3">
                <Link to="/news">News</Link>
              </li>
              <li className="list-inline-item px-3">
                <Link to="/about">About</Link>
              </li>
              <li className="list-inline-item px-3">
                <Link to="/how-to?language=filipino">How Tos</Link>
              </li>
              <li className="list-inline-item px-3">
                <Link to="/faq">FAQs</Link>
              </li>
              {
                !!_.get(CMS_COMPONENTS, "FOOTER_FORMS") && (
                  <li className="list-inline-item px-3">
                    <Link to="/downloadable-form">Forms</Link>
                  </li>
                )
              }
              <li className="list-inline-item px-3">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              {
                !!_.get(CMS_COMPONENTS, "TERMS_AND_CONDITION") && (
                  <li className="list-inline-item px-3">
                    <Link to="/terms-and-condition">Terms and Condition</Link>
                  </li>
                )
              }
            </ul >
            <ul className="social-links list-inline">
              <li className="list-inline-item px-2">
                <i className="fab fa-facebook-square" />
              </li>
              <li className="list-inline-item px-2">
                <i className="fab fa-twitter-square" />
              </li>
              <li className="list-inline-item pl-2">
                <i className="fab fa-linkedin" />
              </li>
            </ul>
          </div >
        </div >
        <hr />
        <div className="sub-footer">
          {/* <div className='row d-flex text-center my-2'>
              <div className='col-md-12'></div>
            </div> */}
          <div className="row d-flex align-items-center my-4">
            <div className="col-12 col-md-6 powered-cont">
              <span className="text-dark d-block"> Powered by </span>
              <a
                href={_.get(CMS_VARIABLES, "FOOTER_LINK")}
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="msys-logo" alt="" src={_.get(CMS_LOGO, "FOOTER_LOGO.cdnUrl")} />
              </a>
            </div>
            <div className="col-12 col-md-6">
              <div className="copyright ">
                © 2020 {_.get(CMS_VARIABLES, "ORGANIZATION")} Local Government
                System. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div >
    </footer >
  );
}

Footer.propTypes = {};

export default Footer;

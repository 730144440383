import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";


function Dummy() {

  const history = useHistory();

  const handleOnGoBack = () => {
    window.close();
  };

  const handleOnGoingBack = () => {
    window.history.back();
  }

  return (
    <div className='row d-flex align-items-center'>
      <button
        onClick={() => {
          history.goBack();
        }}
        type='button'
        className='btn btn-link common-back cms-text-primary'>
        <i className='fas fa-chevron-left' />
        <span> Back </span>
      </button>
      <button
        onClick={handleOnGoBack}
        type='button'
        className='btn btn-link common-back cms-text-primary'>
        <i className='fas fa-chevron-left' />
        <span> Back to app </span>
      </button>
      <button
        onClick={handleOnGoingBack}
        type='button'
        className='btn btn-link common-back cms-text-primary'>
        <i className='fas fa-chevron-left' />
        <span> Please go Back to app </span>
      </button>
      <Link to={-1}> Go back </Link>
    </div>
  );
}

const mapStateToProps = () => ({
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps)
]);

export default enhance(Dummy);

/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { ModalContext } from "App";
import PropTypes from "prop-types";
import iconSearch from "assets/img/icon-search.svg";
import Modal from "modules/common/components/Modal";
import { reactSelectCustomStyle, scrollToTop } from "helper";
import { removeItem, set } from "session";
import imgPlaceHolder from "assets/img/transaction/icon-drilling.svg";
import MiniLoader from "modules/common/components/MiniLoader";
import Select from "react-select";
import EmptyState from "modules/common/components/EmptyState";
import NumberFormat from 'react-number-format';
import useFormValidator from "useFormValidator";
import BPLSSearchBilling from "modules/transaction/containers/TBPLS/container/BPLSSearchBilling";

import BTAXSearchBilling from "modules/transaction/smart-city/BTAX/containers/BTAXSearchBilling";
import BTAXSearchCSV from "modules/transaction/smart-city/BTAX/containers/csv/BTAXSearchCSV";
import BTAXSearchManual from "modules/transaction/smart-city/BTAX/containers/manual/BTAXSearchManual";
import NOVSearchBilling from "modules/transaction/containers/NOV/containers/SearchBilling";
import MiscellaneousFees from "modules/transaction/containers/MISCELLANEOUS-FEES/containers/MiscellaneousFees";
import CmsContext from "../../../../CmsContext";
import * as actions from "../../actions";
import * as c from "../../constants";

const form = {
  system_module_id: "",
  application_status_id: "",
  transaction_type: "",
  module_type: "",
  icon: "",
  label: ""
};

function SelectEgovAndEservicesModal({
  moduleTypeList,
  isLoading,
  getSelectedModuleSystem,
  selectedModuleSystem,
  history,
  match,
  selectedModuleSystemIsLoading,
  initFormData,
  initActiveContent,
  getBillingsImport,
  getOfficesList,
  officesList,
  getMiscFeesList,
  miscFeesList,
  billingSettings,
  bplsBillingSettings
  // getBPLSBillingsImport,
}) {
  const setModal = useContext(ModalContext);
  const cmsContext = useContext(CmsContext);
  const CMS_ICONS = cmsContext.findByType("ICONS");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");
  const [activeContent, setActiveContent] = useState(initActiveContent);
  const [formData, setFormData] = useState(initFormData);
  const [showTransactionType, setShowTransactionType] = useState(false);

  const officeOptions =
  officesList?.map(
    item => {
      const data = {
        label: `${_.get(item, "attributes.name").toUpperCase()}`,
        value: _.get(item, "id"),
        data: item
      };
      return data;
    }
  ) || [];

const miscFeeOptions =
  miscFeesList?.map(
    item => {
      const data = {
        label: `${_.get(item, "attributes.account_title").toUpperCase()}`,
        value: _.get(item, "id"),
        data: item
      };
      return data;
    }
  ) || [];

  const validateForm = useFormValidator(formData, {
    application_status_id: "required",
    amount: "number",
  });

  useEffect(() => {
    validateForm.onSetObjectRules({
      application_status_id: "required",
      amount: "number",
    })
    if (_.get(selectedModuleSystem, "attributes.description", 0) === 'Miscellaneous Fees'
      && _.get(miscFeeOptions?.find(item =>
        _.get(formData, "application_status_id") ===
        _.get(item, "value")
      ), 'data.attributes.amount') === "0.00") {
        validateForm.onSetObjectRules({
          application_status_id: "required",
          amount: "number_range:20-1000000",
        })
      }
  }, [_.get(formData, "application_status_id")])

  useEffect(() => {
    validateForm.validate();
  }, [Object.values(formData).join(",")]);

  useEffect(() => {
    if (initActiveContent) getSelectedModuleSystem(initActiveContent);
  }, [initActiveContent]);

  const handleOnClose = e => {
    if (e) e.preventDefault();
    setModal({ isOpen: false });
  };

  useEffect(() => {
    getBillingsImport();
    getOfficesList();
    // getBPLSBillingsImport();
  }, []);

  const handleSetActiveContent = item => e => {
    e.preventDefault();
    if (item) {
      setFormData({
        ...formData,
        module_type: _.get(item, "attributes.name"),
        icon: _.get(item, "attributes.photo"),
        label: _.get(item, "attributes.description")
      });
      getSelectedModuleSystem(item.id);
    }

    if (item === 0) {
      setFormData(form);
    }
    setActiveContent(item.id);
    scrollToTop();
  };

  const handleOnChangeSelect = key => e => {
    if (_.get(e, "data.name") === 'PUV' || _.get(e, "data.name") === 'TRICYCLE') {
      const value = _.get(e, "data.id").toString() || '';

      setFormData({
        ...formData,
        [key]: e.value,
        application_status_id: value,
        system_module_id: _.get(e, "data.system_module_id"),
        transaction_type: _.get(e, "data.name")
      });
    }
    else {
      setFormData({
        ...formData,
        [key]: e.value,
        system_module_id: _.get(e, "data.attributes.system_module_id"),
        transaction_type: _.get(e, "data.attributes.name")
      });
    }
  };
  const handleOnChangeSelectMiscFee = key => e => {
    const args = {
      ...formData,
      [key]: e.value,
      system_module_id: _.get(e, "data.attributes.system_module_id"),
      transaction_type: _.get(e, "data.attributes.name"),
      amount: _.get(e, 'data.attributes.amount') || '0.00',
    }
    if(key === 'office_id'){
      getMiscFeesList({[key]: e.value});
      return setFormData({
        ...args,
        amount: key === 'office_id' && _.get(formData, 'office_id') === e.value ? _.get(formData, 'amount') : _.get(e, 'data.attributes.amount') || '0.00',
        application_status_id: _.get(formData, 'office_id') === e.value ? _.get(formData, 'application_status_id') : "",
      });
    }
    return setFormData(args);
  };

  const onChangeNumber = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleOnProceed = () => {
    localStorage.setItem("transaction", JSON.stringify(formData));
    const modType = _.get(formData, "module_type");
    const transType = _.get(formData, "transaction_type");
    const rootUrl = `${match.path}create-transaction`;

    const getRedirect = type => {
      try {
        const module = {
          T_BPLS: () => {
            // BPLS: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/business-permit-new`,
                RENEWAL: `${rootUrl}/business-permit-renew`
                // TEMPORARY: `${rootUrl}/business-permit-new-comp`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          BPLS: () => {
            // BPLS: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/e2e-business-permit-new`,
                RENEWAL: `${rootUrl}/e2e-business-permit-renew`,
                CHANGE: `${rootUrl}/e2e-business-permit-change`,
                QUARTERLY: `${rootUrl}/e2e-business-permit-quarterly`,
                DELINQUENT: `${rootUrl}/e2e-business-permit-delinquent`,
                RETIREMENT: `${rootUrl}/e2e-business-permit-retirement`
                // TEMPORARY: `${rootUrl}/business-permit-new-comp`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          MVOPS: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/motorized-permit-new`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          SCMS: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/senior-citizen-new`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          CDL: () => {
            const getTransactionType = item => {
              const x = {
                INDIVIDUAL: `${rootUrl}/cedula-individual`,
                CORPORATION: `${rootUrl}/cedula-corporation`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          PSA: () => {
            const getTransactionType = item => {
              const x = {
                "BIRTH CERTIFICATE": `${rootUrl}/birth-certificate`,
                "DEATH CERTIFICATE": `${rootUrl}/death-certificate`,
                "MARRIAGE CERTIFICATE": `${rootUrl}/marriage-certificate`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          OHP: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/occupational-health`,
                RENEWAL: `${rootUrl}/occupational-health-renew`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          HC: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/health-certificate`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          OP: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/occupational-permit`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          OVRS: () => {
            const getTransactionType = item => {
              const x = {
                "OVR PAYMENT": `${rootUrl}/ordinance-violation-receipt`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          PC: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/police-clearance-new`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          RPTAX: () => {
            const getTransactionType = item => {
              const x = {
                Current: `${rootUrl}/current`,
                Deliquent: `${rootUrl}/deliquent`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          EHELP: () => {
            const getTransactionType = item => {
              const x = {
                "Electronic Immunization Registration": `${rootUrl}/vaccination`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          RPT: () => {
            const getTransactionType = item => {
              const x = {
                "RPT Land Payment": `${rootUrl}/rpt-land-transaction`,
                "RPT Building Payment": `${rootUrl}/rpt-building-transaction`,
                "RPT Machinery Payment": `${rootUrl}/rpt-machinery-transaction`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          SPECIAL_PERMIT: () => {
            const getTransactionType = item => {
              const x = {
                "PUV": `${rootUrl}/puv`,
                "TRICYCLE": `${rootUrl}/tricycle`
              };
              return x[item];
            };
            return getTransactionType(transType);
          }
        };
        return module[type] && module[type]();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Something went wrong!");
        return "/";
      }
    };
    // adjust this line if retrieve features is needed
    if (transType === "RENEW") {
      set("RETRIEVE_OLD_DATA_MODAL", 1);
    }

    history.push(getRedirect(modType));
    removeItem("application-info");
    handleOnClose();
  };

  const loadBTAX = (settings, dataForm) => {
    if (settings === "CSV") {
      return (
        <BTAXSearchCSV
          module={dataForm}
          setShowTransactionType={setShowTransactionType}
        />
      );
    }
    if (settings === "MANUAL") {
      return (
        <BTAXSearchManual
          module={dataForm}
          setShowTransactionType={setShowTransactionType}
        />
      );
    }
    return <BTAXSearchBilling module={dataForm} />;
  };

  const loadTBPLS = (settings, dataForm) => {
    // if (settings === "CSV") {
    //   return <BPLSSearchBilling module={dataForm} />;
    // }
    return <BPLSSearchBilling module={dataForm} />;
  };

  const hasProceedButton = () => {
    let arr = ["BTAX", "NOV"];

    if (
      _.get(formData, "transaction_type") === "DELINQUENT" ||
      _.get(formData, "transaction_type") === "QUARTERLY"
    ) {
      arr = [...arr, "T_BPLS"];
    }

    if (_.get(CMS_COMPONENTS, "RPTAX") === "ANGELES") {
      return arr.concat("RPTAX");
    }

    return arr;
  };

  const renderSelectedModule = () => {
    let options;
    if (_.get(selectedModuleSystem, 'attributes.description') === 'Special Permit') {
      options = _.get(selectedModuleSystem, "attributes.application_statuses", []).map(
        item => {
          const data = {
            label:
              `${_.get(item, "name")} ${!_.isEmpty(_.get(item, "description_filipino")) ||
                (_.get(item, "description_filipino")) !== null ? `| ${_.get(item, "description_filipino")}` : ""
              }`,
            value: _.get(item, "id"),
            data: item
          };
          return data;
        }
      ) || [];
    }
    else {
      options =
        _.get(selectedModuleSystem, "included.applicationStatuses", []).map(
          item => {
            const subTitle =
              (_.get(item, "attributes.name").indexOf("QUARTERLY") > -1 &&
                " (Current Year)") ||
              (_.get(item, "attributes.name").indexOf("DELINQUENT") > -1 &&
                " (Previous Year)") ||
              "";

            const data = {
              label: `${_.get(item, "attributes.name")} ${!_.isEmpty(_.get(item, "attributes.description_filipino")) ||
                !_.isEmpty(subTitle)
                ? "|"
                : ""
                } ${_.get(item, "attributes.description_filipino") ||
                ""} ${subTitle}`,
              value: _.get(item, "id"),
              data: item
            };
            return data;
          }
        ) || [];
    }

    return selectedModuleSystemIsLoading ? (
      <div
        className="d-flex align-items-center"
        style={{
          height: 414
        }}
      >
        <MiniLoader />
      </div>
    ) : (
      <>
        <div className="row d-flex justify-content-center mb-3  animated fadeIn">
          <div className="col-12 col-md-10 col-lg-8 p-2">
            <div className="item-card-active cms-outline-primary">
              <div className="container justify-content-center align-items-center h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-4 pl-4 pr-2 d-flex justify-content-center align-items-end">
                    {+_.get(selectedModuleSystem, "attributes.is_active", 0) ===
                      0 && (
                        <i
                          className="fa fa-times text-danger h3 icon-circle"
                          style={{ top: 0, left: 10, position: "absolute" }}
                        />
                      )}
                    {+_.get(selectedModuleSystem, "attributes.is_active", 1) ===
                      1 && (
                        <i
                          className="fa fa-check text-success h3 icon-circle"
                          style={{ top: 0, left: 10, position: "absolute" }}
                        />
                      )}
                    <img
                      className="transaction-icon"
                      src={
                        _.get(
                          selectedModuleSystem,
                          "attributes.other_information"
                        )
                          ? _.get(
                            _.get(
                              CMS_ICONS,
                              _.get(
                                selectedModuleSystem,
                                "attributes.other_information"
                              ),
                              {}
                            ),
                            "cdnUrl",
                            ""
                          )
                          : imgPlaceHolder
                      }
                      alt="TEST"
                    />
                  </div>
                  <div className="col pl-0 d-flex flex-column justify-content-start align-items-start">
                    <h3 className="cms-text-primary">
                      {_.get(selectedModuleSystem, "attributes.description")}
                    </h3>
                    <h6 className="cms-text-primary">
                      {_.get(
                        selectedModuleSystem,
                        "attributes.description_filipino"
                      )}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {_.get(selectedModuleSystem, "attributes.is_active", 0) === 1 &&
          _.get(selectedModuleSystem, "attributes.is_coming_soon", 0) === 1 && (
            <div className="col-12">
              <EmptyState message="Soon to be available" />
            </div>
          )}
                {_.get(selectedModuleSystem, "attributes.is_active", 0) === 1 &&
          _.get(selectedModuleSystem, "attributes.is_coming_soon", 0) === 0 &&
          _.get(selectedModuleSystem, "attributes.description", 0) === 'Miscellaneous Fees' && (
            <>
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-md-10 col-lg-8">
                  <div className="form-wrapper mb-3">
                    <div className="full-group ">
                      <div className="form-row">
                        <div className="form-input-group col-12">
                          <label
                            htmlFor="last_name"
                            className="full-label font-weight-bold mb-0"
                          >
                            Office
                          </label>
                          <Select
                            styles={reactSelectCustomStyle}
                            value={
                              officeOptions.find(
                                item =>
                                  _.get(formData, "office_id") ===
                                  _.get(item, "value")
                              ) || ""
                            }
                            onChange={handleOnChangeSelectMiscFee(
                              "office_id"
                            )}
                            options={officeOptions}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-input-group col-12">
                          <label
                            htmlFor="last_name"
                            className="full-label font-weight-bold mb-0"
                          >
                            Type of Miscellaneous Fee
                            {validateForm.renderMessage("application_status_id")}
                          </label>
                          <Select
                            styles={reactSelectCustomStyle}
                            isDisabled={_.isEmpty(_.get(formData, 'office_id'))}
                            value={
                              miscFeeOptions.find(
                                item =>
                                  _.get(formData, "application_status_id") ===
                                  _.get(item, "value")
                              ) || ""
                            }
                            onChange={handleOnChangeSelectMiscFee(
                              "application_status_id"
                            )}
                            options={miscFeeOptions}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-3 form-row">
                        <div className="form-input-group col-12">
                          <label htmlFor="last_name" className="full-label mb-0">
                            Amount{" "}
                            {validateForm.renderMessage("amount")}
                          </label>
                          <NumberFormat
                            className="full-input text-uppercase"
                            type="text"
                            name="amount"
                            id="amount"
                            allowLeadingZeros
                            decimalScale={2}
                            decimalSeparator="."
                            disabled={
                              (_.get(miscFeeOptions?.find(item =>
                                _.get(formData, "application_status_id") ===
                                _.get(item, "value")
                              ), 'data.attributes.amount') !== "0.00")
                            }
                            value={_.get(formData, "amount", 0) || ""}
                            onChange={onChangeNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <MiscellaneousFees selectedModuleAndTransaction={formData} />
                  </div>
                </div>
              </div>
            </>
          )}
        {_.get(selectedModuleSystem, "attributes.is_active", 0) === 1 &&
          _.get(selectedModuleSystem, "attributes.is_coming_soon", 0) === 0 &&
          _.get(selectedModuleSystem, "attributes.description", 0) !== 'Miscellaneous Fees' && (
            <>
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-md-10 col-lg-8">
                  <div className="form-wrapper mb-3">
                    {!showTransactionType && (
                      <div className="full-group ">
                        <div className="form-row">
                          <div className="form-input-group col-12">
                            <label
                              htmlFor="last_name"
                              className="full-label mb-0 cms-text-muted-dark"
                            >
                              Transaction Type
                            </label>
                            <Select
                              styles={reactSelectCustomStyle}
                              value={
                                options.find(
                                  item =>
                                    +_.get(formData, "application_status_id") ===
                                    +_.get(item, "value")
                                ) || ""
                              }
                              onChange={handleOnChangeSelect(
                                "application_status_id"
                              )}
                              options={
                                // Hide Delinquent option for Smart City RPTAX Transaction
                                _.get(selectedModuleSystem, "attributes.name") === "RPTAX" ?
                                  options.filter((option) => option.value !== "23") :
                                  options
                              }
                              components={{
                                IndicatorSeparator: () => null
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {_.get(formData, "module_type") === "T_BPLS" &&
                      _.get(formData, "transaction_type") === "QUARTERLY" &&
                      loadTBPLS(
                        _.get(bplsBillingSettings, "1.attributes.source"),
                        formData
                      )}
                    {_.get(formData, "module_type") === "T_BPLS" &&
                      _.get(formData, "transaction_type") === "DELINQUENT" &&
                      loadTBPLS(
                        _.get(bplsBillingSettings, "1.attributes.source"),
                        formData
                      )}

                    {_.get(formData, "module_type") === "BTAX" &&
                      _.get(formData, "transaction_type") === "TAX PAYMENT" &&
                      loadBTAX(
                        _.get(billingSettings, "1.attributes.source"),
                        formData
                      )}
                    {_.get(formData, "module_type") === "NOV" &&
                      _.get(formData, "transaction_type") === "NOV PAYMENT" && (
                        <NOVSearchBilling module={formData} />
                      )}
                  </div>
                </div>
              </div>
              {hasProceedButton().indexOf(_.get(formData, "module_type")) <
                0 && (
                  <div className="row d-flex justify-content-center mb-5">
                    <div className="col-12 col-md-10 col-lg-8 p-2">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg w-100 cms-bg-color-primary"
                        onClick={handleOnProceed}
                        disabled={_.isEmpty(
                          _.get(formData, "application_status_id")
                        )}
                        style={{
                          cursor: _.isEmpty(
                            _.get(formData, "application_status_id")
                          )
                            ? "not-allowed"
                            : "pointer"
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                )}
              {hasProceedButton().indexOf(_.get(formData, "module_type")) >
                -1 && <div className="pb-5" />}
            </>
          )}
      </>
    );
  };

  const renderModuleList = () => (
    <>
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col-6 text-center">
            <span className="cms-text-primary font-weight-bold">
              {" "}
              E-Government{" "}
            </span>
            <div>
              {moduleTypeList.length > 0 ? (
                moduleTypeList
                  .filter(
                    x =>
                      _.get(x, "attributes.is_active") &&
                      _.get(x, "attributes.category") === "E-Government"
                  )
                  .map(item => {
                    return (
                      <React.Fragment key={item.id}>
                        <button
                          key={`module-${item.id}`}
                          type="button"
                          // disabled={+!_.get(item, 'attributes.is_active')}
                          className="transaction-button col-12 p-2 btn-blank"
                          title={
                            +_.get(item, "attributes.is_active")
                              ? _.get(item, "attributes.description")
                              : "Coming Soon!"
                          }
                          onClick={handleSetActiveContent(item)}
                        >
                          <div className="item-card animated fadeIn">
                            <div className="container-fluid h-100">
                              <div className="row d-flex align-items-center h-100">
                                <div className="col-4 align-self-center">
                                  <img
                                    className="transaction-icon"
                                    src={
                                      _.get(item, "attributes.other_information")
                                        ? _.get(
                                          _.get(
                                            CMS_ICONS,
                                            _.get(
                                              item,
                                              "attributes.other_information"
                                            ),
                                            {}
                                          ),
                                          "cdnUrl",
                                          ""
                                        )
                                        : imgPlaceHolder
                                    }
                                    alt={_.get(item, "name")}
                                  />
                                </div>
                                <div className="col-8 align-self-center pl-0 text-left">
                                  <h3 className="transaction-description cms-text-primary cms-text-primary">
                                    {_.get(item, "attributes.description") || ""}
                                  </h3>
                                  <h6>
                                    {_.get(
                                      item,
                                      "attributes.description_filipino"
                                    ) || ""}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      </React.Fragment>
                    );
                  })
              ) : (
                <div className="col-12">
                  <EmptyState />
                </div>
              )}
            </div>
          </div>
          <div className="col-6 text-center">
            <span className="cms-text-primary font-weight-bold"> E-Services </span>
            <div>
              {moduleTypeList.length > 0 ? (
                moduleTypeList
                  .filter(
                    x =>
                      _.get(x, "attributes.is_active") &&
                      _.get(x, "attributes.category") === "E-Services"
                  )
                  .map(item => {
                    return (
                      <React.Fragment key={item.id}>
                        <button
                          key={`module-${item.id}`}
                          type="button"
                          // disabled={+!_.get(item, 'attributes.is_active')}
                          className="transaction-button col-12 p-2 btn-blank"
                          title={
                            +_.get(item, "attributes.is_active")
                              ? _.get(item, "attributes.description")
                              : "Coming Soon!"
                          }
                          onClick={handleSetActiveContent(item)}
                        >
                          <div className="item-card animated fadeIn">
                            <div className="container-fluid h-100">
                              <div className="row d-flex align-items-center h-100">
                                <div className="col-4 align-self-center">
                                  <img
                                    className="transaction-icon"
                                    src={
                                      _.get(item, "attributes.other_information")
                                        ? _.get(
                                          _.get(
                                            CMS_ICONS,
                                            _.get(
                                              item,
                                              "attributes.other_information"
                                            ),
                                            {}
                                          ),
                                          "cdnUrl",
                                          ""
                                        )
                                        : imgPlaceHolder
                                    }
                                    alt={_.get(item, "name")}
                                  />
                                </div>
                                <div className="col-8 align-self-center pl-0 text-left">
                                  <h3 className="transaction-description cms-text-primary cms-text-primary">
                                    {_.get(item, "attributes.description") || ""}
                                  </h3>
                                  <h6>
                                    {_.get(
                                      item,
                                      "attributes.description_filipino"
                                    ) || ""}
                                  </h6>
                                  {/* <i className='text-info'>
                          {_.get(item, "attributes.description") || ""}
                        </i> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      </React.Fragment>
                    );
                  })
              ) : (
                <div className="col-12">
                  <EmptyState />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-none">
        <div className="row">
          <div className="col-12">
            <span className="cms-text-primary font-weight-bold">
              {" "}
              E-Government{" "}
            </span>
            <div>
              {moduleTypeList.length > 0 ? (
                moduleTypeList
                  .filter(
                    x =>
                      _.get(x, "attributes.is_active") &&
                      _.get(x, "attributes.category") === "E-Government"
                  )
                  .map(item => {
                    return (
                      <React.Fragment key={item.id}>
                        <button
                          key={`module-${item.id}`}
                          type="button"
                          // disabled={+!_.get(item, 'attributes.is_active')}
                          className="transaction-button col-12 p-2 btn-blank"
                          title={
                            +_.get(item, "attributes.is_active")
                              ? _.get(item, "attributes.description")
                              : "Coming Soon!"
                          }
                          onClick={handleSetActiveContent(item)}
                        >
                          <div className="item-card animated fadeIn">
                            <div className="container-fluid h-100">
                              <div className="row d-flex align-items-center h-100">
                                <div className="col-4 align-self-center">
                                  <img
                                    className="transaction-icon"
                                    src={
                                      _.get(item, "attributes.other_information")
                                        ? _.get(
                                          _.get(
                                            CMS_ICONS,
                                            _.get(
                                              item,
                                              "attributes.other_information"
                                            ),
                                            {}
                                          ),
                                          "cdnUrl",
                                          ""
                                        )
                                        : imgPlaceHolder
                                    }
                                    alt={_.get(item, "name")}
                                  />
                                </div>
                                <div className="col-8 align-self-center pl-0 text-left">
                                  <h3 className="transaction-description cms-text-primary cms-text-primary">
                                    {_.get(item, "attributes.description") || ""}
                                  </h3>
                                  <h6>
                                    {_.get(
                                      item,
                                      "attributes.description_filipino"
                                    ) || ""}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      </React.Fragment>
                    );
                  })
              ) : (
                <div className="col-12">
                  <EmptyState />
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <span className="cms-text-primary font-weight-bold"> E-Services </span>
            <div>
              {moduleTypeList.length > 0 ? (
                moduleTypeList
                  .filter(
                    x =>
                      _.get(x, "attributes.is_active") &&
                      _.get(x, "attributes.category") === "E-Services"
                  )
                  .map(item => {
                    return (
                      <React.Fragment key={item.id}>
                        <button
                          key={`module-${item.id}`}
                          type="button"
                          // disabled={+!_.get(item, 'attributes.is_active')}
                          className="transaction-button col-12 p-2 btn-blank"
                          title={
                            +_.get(item, "attributes.is_active")
                              ? _.get(item, "attributes.description")
                              : "Coming Soon!"
                          }
                          onClick={handleSetActiveContent(item)}
                        >
                          <div className="item-card animated fadeIn">
                            <div className="container-fluid h-100">
                              <div className="row d-flex align-items-center h-100">
                                <div className="col-4 align-self-center">
                                  <img
                                    className="transaction-icon"
                                    src={
                                      _.get(item, "attributes.other_information")
                                        ? _.get(
                                          _.get(
                                            CMS_ICONS,
                                            _.get(
                                              item,
                                              "attributes.other_information"
                                            ),
                                            {}
                                          ),
                                          "cdnUrl",
                                          ""
                                        )
                                        : imgPlaceHolder
                                    }
                                    alt={_.get(item, "name")}
                                  />
                                </div>
                                <div className="col-8 align-self-center pl-0 text-left">
                                  <h3 className="transaction-description cms-text-primary cms-text-primary">
                                    {_.get(item, "attributes.description") || ""}
                                  </h3>
                                  <h6>
                                    {_.get(
                                      item,
                                      "attributes.description_filipino"
                                    ) || ""}
                                  </h6>
                                  {/* <i className='text-info'>
                         {_.get(item, "attributes.description") || ""}
                       </i> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      </React.Fragment>
                    );
                  })
              ) : (
                <div className="col-12">
                  <EmptyState />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Modal.Body className="clear-modal transaction-modal">
      <button
        type="button"
        className="close modal-close cms-text-primary p-3"
        onClick={handleOnClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            {activeContent ? (
              <button
                type="button"
                className="btn btn-link back-button text-primary cms-text-primary p-3"
                onClick={handleSetActiveContent(0)}
              >
                <i className="fas fa-chevron-left" />
                <span className="text">Back</span>
              </button>
            ) : (
              ""
            )}
            <div className="transaction-title pt-4">
              <h2 className="d-inline-block">
                <img
                  className="transaction-head-icon mr-2 mt-0 d-inline-block"
                  src={iconSearch}
                  alt=""
                />
                Select Transaction
              </h2>
            </div>
          </div>
        </div>
        {activeContent ? (
          renderSelectedModule()
        ) : (
          <>
            {isLoading ? (
              <div
                className="d-flex align-items-center"
                style={{
                  height: 438
                }}
              >
                <MiniLoader />
              </div>
            ) : (
              renderModuleList()
            )}
          </>
        )}
      </div>
    </Modal.Body>
  );
}

SelectEgovAndEservicesModal.defaultProps = {
  initActiveContent: 0,
  initFormData: {}
};

SelectEgovAndEservicesModal.propTypes = {
  moduleTypeList: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSelectedModuleSystem: PropTypes.instanceOf(Function).isRequired,
  getBillingsImport: PropTypes.instanceOf(Function).isRequired,
  // getBPLSBillingsImport: PropTypes.instanceOf(Function).isRequired,
  selectedModuleSystem: PropTypes.instanceOf(Object).isRequired,
  selectedModuleSystemIsLoading: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  initFormData: PropTypes.instanceOf(Object),
  billingSettings: PropTypes.instanceOf(Object).isRequired,
  bplsBillingSettings: PropTypes.instanceOf(Object).isRequired,
  initActiveContent: PropTypes.number,
  getOfficesList: PropTypes.instanceOf(Function).isRequired,
  officesList: PropTypes.instanceOf(Array).isRequired,
  getMiscFeesList: PropTypes.instanceOf(Function).isRequired,
  miscFeesList: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  moduleTypeList: _.get(api, `${c.GET_MODULE_TYPE_LIST}`, []).list || [],
  isLoading: _.get(api, "loading", []).indexOf(c.GET_MODULE_TYPE_LIST) > -1,
  selectedModuleSystemIsLoading:
    _.get(api, "loading", []).indexOf(c.GET_SELECTED_MODULE_SYSTEM) > -1,
  selectedModuleSystem:
    _.get(api, `${c.GET_SELECTED_MODULE_SYSTEM}.item`) || {},
  billingSettings: _.get(api, `${c.GET_BILLINGS_IMPORT}.item`) || {},
  bplsBillingSettings: _.get(api, `${c.GET_BPLS_BILLINGS_IMPORT}.item`) || {},
  officesList: _.get(api, `${c.GET_OFFICES_LIST}`, []).list || [],
  miscFeesList: _.get(api, `${c.GET_MISC_FEES_LIST}`, []).list || [],
});
const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(SelectEgovAndEservicesModal);

import React, {useContext} from 'react'
import { ModalContext } from "App";
import _ from 'lodash'
import DOMPurify from "dompurify";
import CmsContext from "../../../CmsContext";

const ImportantAnnouncementModal = () => {
  const cmsContext = useContext(CmsContext);
  const setModal = useContext(ModalContext);
  const CMS_CONTENTS = cmsContext.findByType("CONTENTS");

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  if(_.isEmpty(_.get(CMS_CONTENTS, 'IMPORTANT_ANNOUNCEMENT'))) return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <i className="fa fa-spin fa-spinner" />
    </div>
  )

  return (
    <div className="position-relative w-100">
      <button className="btn btn-none position-absolute shadow-none" style={{right: 0, top: 0,}} type="button" onClick={closeModal}>
        <i className="fas fa-times fa-2x text-dark" />
      </button>
      <section
        className="container px-5 py-4"
        style={{
          // backgroundColor: "#f5f5f5", 
          borderRadius: '1.2em' }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(_.get(CMS_CONTENTS, 'IMPORTANT_ANNOUNCEMENT'))
        }}
      />
    </div>
  )
}

export default ImportantAnnouncementModal

export const blank = {};

export const TIN_NUMBER_REGEX = /\d{3,3}-\d{3,3}-\d{3,3}-\d{5,5}/;

export const status = [
  {
    label: "PENDING",
    value: "PENDING",
    state: "warning",
    textColor: "dark",
    description: "Application is waiting review/approval"
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
    state: "danger",
    textColor: "white",
    description: "Application is Cancelled"
  },
  {
    label: "PAYMENT FAILED",
    value: "PAYMENT FAILED",
    state: "danger",
    textColor: "white",
    description: "Application payment failed"
  },
  {
    label: "FOR RESUBMISSION",
    value: "FOR RESUBMISSION",
    state: "warning",
    textColor: "dark",
    description: "Application needs to be modified//updated"
  },
  {
    label: "EXPIRED REFERENCE NUMBER",
    value: "EXPIRED REFERENCE NUMBER",
    state: "danger",
    textColor: "white",
    description: "Payment Reference Number is no longer valid"
  },
  {
    label: "RECEIVED",
    value: "RECEIVED",
    state: "success",
    textColor: "white",
    description: "Application has been received"
  },
  {
    label: "FOR CHECKING",
    value: "FOR CHECKING",
    state: "info",
    textColor: "white",
    description: "Application is currently being reviewed"
  },
  {
    label: "FOR DELIVERY",
    value: "FOR DELIVERY",
    state: "info",
    textColor: "dark",
    description: "Document is now ready for delivery"
  },
  {
    label: "FOR PRINTING",
    value: "FOR PRINTING",
    state: "info",
    textColor: "dark",
    description:
      "An SMS Notification will be sent, once your document is printed."
  },
  {
    label: "FOR APPEARANCE",
    value: "FOR APPEARANCE",
    state: "info",
    textColor: "dark",
    description: "Applicant is required to come physically"
  },
  {
    label: "FOR APPOINTMENT",
    value: "FOR APPOINTMENT",
    state: "info",
    textColor: "dark",
    description: "Applicant needs to choose a schedule"
  },
  {
    label: "WAITING FOR PAYMENT",
    value: "WAITING FOR PAYMENT",
    state: "secondary",
    textColor: "white",
    description: "Application has not been paid"
  },
  {
    label: "REJECT",
    value: "REJECT",
    state: "danger",
    textColor: "white",
    description: "Application has been rejected"
  },
  {
    label: "REJECTED",
    value: "REJECTED",
    state: "danger",
    textColor: "white",
    description: "Application has been rejected"
  },
  {
    label: "FOR ASSESSMENT (ADMIN)",
    value: "FOR ASSESSMENT (ADMIN)",
    state: "success",
    textColor: "white",
    description: "Application is for assessment"
  },
  {
    label: "FOR ASSESSMENT (TREASURY)",
    value: "FOR ASSESSMENT (TREASURY)",
    state: "success",
    textColor: "white",
    description: "Application is for assessment"
  },
  {
    label: "FINALIZED",
    value: "FINALIZED",
    state: "success",
    textColor: "white",
    description: "Application is finalized"
  },
  {
    label: "PAID",
    value: "PAID",
    state: "success",
    textColor: "white",
    description: "Application is already paid"
  },
  {
    label: "RELEASED",
    value: "RELEASED",
    state: "success",
    textColor: "white",
    description: "Application is for release"
  },
  {
    label: "FOR PICK-UP",
    value: "FOR PICK-UP",
    state: "success",
    textColor: "white",
    description: "The requested application is already for pick-up"
  },
  {
    label: "APPROVED",
    value: "APPROVED",
    state: "success",
    textColor: "white",
    description: "The requested application is already approved",
  },
  {
    label: "PENDING LAB TEST",
    value: "PENDING LAB TEST",
    state: "warning",
    textColor: "dark",
    description: "Awaiting for laboratory test result"
  },
  {
    label: "PRINTED",
    value: "PRINTED",
    state: "success",
    textColor: "white",
    description: "The requested application is already printed"
  },
  {
    label: "FOR REFUND APPROVAL",
    value: "FOR REFUND APPROVAL",
    state: "danger",
    textColor: "white",
    description: "Application is for refund approval"
  },
  {
    label: "PROCESSING REFUND",
    value: "PROCESSING REFUND",
    state: "info",
    textColor: "dark",
    description: "Applicant currently processing refund request"
  },
  {
    label: "COMPUTED",
    value: "COMPUTED",
    state: "info",
    textColor: "white",
    description: "Applicant has been computed"
  },
  {
    label: "SYNCED",
    value: "SYNCED",
    state: "info",
    textColor: "white",
    description: "Applicant has been synced"
  },
  {
    label: "HEALTH CERTIFICATE GENERATED",
    value: "HEALTH CERTIFICATE GENERATED",
    state: "info",
    textColor: "white",
    description: "The requested application is already generated"
  },
  {
    label: "FOR REASSESSMENT",
    value: "FOR REASSESSMENT",
    state: "warning",
    textColor: "dark",
    description: "Application needs to be modified//updated"
  },
  {
    label: "EXPIRED SOA",
    value: "EXPIRED SOA",
    state: "danger",
    textColor: "white",
    description: "Generated SOA is no longer valid"
  },
  {
    label: "ASSESSED BY PLANNING DEPARTMENT",
    value: "ASSESSED BY PLANNING DEPARTMENT",
    state: "success",
    textColor: "white",
    description: "Application is assessed by planning department"
  },
  {
    label: "ASSESSED BY ENGINEERING DEPARTMENT",
    value: "ASSESSED BY ENGINEERING DEPARTMENT",
    state: "success",
    textColor: "white",
    description: "Application is assessed by engineering department"
  },
  {
    label: "FOR ACCOUNT CREATION",
    value: "FOR ACCOUNT CREATION",
    state: "warning",
    textColor: "white",
    description: "Application is for account creation"
  },
  {
    label: "ASSESSED BY BPLO",
    value: "ASSESSED BY BPLO",
    state: "success",
    textColor: "white",
    description: "Application is assessed by BPLO"
  },
  {
    label: "VERIFIED",
    value: "VERIFIED",
    state: "success",
    textColor: "white",
    description: "Application is verified"
  },
  {
    label: "ONGOING",
    value: "ONGOING",
    state: "ongoing",
    textColor: "white",
    description: "Application is on going"
  },
];

export const transaction_status = [
  {
    label: "PENDING",
    value: "PENDING",
    state: "bg-warning",
    textColor: "dark",
    description: "Application is waiting review/approval"
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
    state: "bg-danger",
    textColor: "dark",
    description: "Application is Cancelled"
  },
  {
    label: "FOR RESUBMISSION",
    value: "FOR RESUBMISSION",
    state: "bg-warning",
    textColor: "dark",
    description: "Application needs to be modified//updated"
  },
  {
    label: "EXPIRED REFERENCE NUMBER",
    value: "EXPIRED REFERENCE NUMBER",
    state: "bg-danger",
    textColor: "white",
    description: "Payment Reference Number is no longer valid"
  },
  {
    label: "RECEIVED",
    value: "RECEIVED",
    state: "bg-success",
    textColor: "white",
    description: "Application has been received"
  },
  {
    label: "FOR CHECKING",
    value: "FOR CHECKING",
    state: "bg-info",
    textColor: "white",
    description: "Application is currently being reviewed"
  },
  {
    label: "FOR DELIVERY",
    value: "FOR DELIVERY",
    state: "bg-info",
    textColor: "white",
    description: "Document is now ready for delivery"
  },
  {
    label: "FOR PRINTING",
    value: "FOR PRINTING",
    state: "bg-info",
    textColor: "white",
    description:
      "An SMS Notification will be sent, once your document is printed."
  },
  {
    label: "FOR APPEARANCE",
    value: "FOR APPEARANCE",
    state: "bg-info",
    textColor: "white",
    description: "Applicant is required to come physically"
  },
  {
    label: "FOR APPOINTMENT",
    value: "FOR APPOINTMENT",
    state: "bg-info",
    textColor: "white",
    description: "Applicant needs to choose a schedule"
  },
  {
    label: "WAITING FOR PAYMENT",
    value: "WAITING FOR PAYMENT",
    state: "bg-secondary",
    textColor: "white",
    description: "Application has not been paid"
  },
  {
    label: "REJECT",
    value: "REJECT",
    state: "bg-danger",
    textColor: "white",
    description: "Application has been rejected"
  },
  {
    label: "REJECTED",
    value: "REJECTED",
    state: "bg-danger",
    textColor: "white",
    description: "Application has been rejected"
  },
  {
    label: "PAID",
    value: "PAID",
    state: "bg-success",
    textColor: "white",
    description: "Application is already paid"
  },
  {
    label: "FOR PICK-UP",
    value: "FOR PICK-UP",
    state: "bg-success",
    textColor: "white",
    description: "The requested application is already for pick-up"
  },
  {
    label: "APPROVED",
    value: "APPROVED",
    state: "bg-success",
    textColor: "white",
    description: "The requested application is already approved"
  },
  {
    label: "PENDING LAB TEST",
    value: "PENDING LAB TEST",
    state: "bg-warning",
    textColor: "dark",
    description: "Awaiting for laboratory test result"
  },
  {
    label: "PRINTED",
    value: "PRINTED",
    state: "bg-success",
    textColor: "white",
    description: "The requested application is already printed"
  },
  {
    label: "FOR REFUND APPROVAL",
    value: "FOR REFUND APPROVAL",
    state: "bg-danger",
    textColor: "white",
    description: "Application is for refund approval"
  },
  {
    label: "PROCESSING REFUND",
    value: "PROCESSING REFUND",
    state: "bg-info",
    textColor: "white",
    description: "Applicant currently processing refund request"
  },
  {
    label: "HEALTH CERTIFICATE GENERATED",
    value: "HEALTH CERTIFICATE GENERATED",
    state: "info",
    textColor: "white",
    description: "The requested application is already generated"
  },
  {
    label: "EXPIRED SOA",
    value: "EXPIRED SOA",
    state: "bg-danger",
    textColor: "white",
    description: "Generated SOA is no longer valid"
  }
];

export const statusEbpls = [
  {
    label: "PENDING",
    value: "PENDING",
    state: "pending",
    textColor: "white",
    description: "Application is waiting review/approval"
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
    state: "danger",
    textColor: "white",
    description: "Application is Cancelled"
  },
  {
    label: "PAYMENT FAILED",
    value: "PAYMENT FAILED",
    state: "danger",
    textColor: "white",
    description: "Application payment failed"
  },
  {
    label: "FOR RESUBMISSION",
    value: "FOR RESUBMISSION",
    state: "warning",
    textColor: "dark",
    description: "Application needs to be modified//updated"
  },
  {
    label: "EXPIRED REFERENCE NUMBER",
    value: "EXPIRED REFERENCE NUMBER",
    state: "danger",
    textColor: "white",
    description: "Payment Reference Number is no longer valid"
  },
  {
    label: "RECEIVED",
    value: "RECEIVED",
    state: "success",
    textColor: "white",
    description: "Application has been received"
  },
  {
    label: "FOR CHECKING",
    value: "FOR CHECKING",
    state: "info",
    textColor: "white",
    description: "Application is currently being reviewed"
  },
  {
    label: "FOR DELIVERY",
    value: "FOR DELIVERY",
    state: "info",
    textColor: "dark",
    description: "Document is now ready for delivery"
  },
  {
    label: "FOR PRINTING",
    value: "FOR PRINTING",
    state: "info",
    textColor: "dark",
    description:
      "An SMS Notification will be sent, once your document is printed."
  },
  {
    label: "FOR APPEARANCE",
    value: "FOR APPEARANCE",
    state: "info",
    textColor: "dark",
    description: "Applicant is required to come physically"
  },
  {
    label: "FOR APPOINTMENT",
    value: "FOR APPOINTMENT",
    state: "info",
    textColor: "dark",
    description: "Applicant needs to choose a schedule"
  },
  {
    label: "WAITING FOR PAYMENT",
    value: "WAITING FOR PAYMENT",
    state: "secondary",
    textColor: "white",
    description: "Application has not been paid"
  },
  {
    label: "REJECTED",
    value: "REJECTED",
    state: "rejected",
    textColor: "white",
    description: "Application has been rejected"
  },
  {
    label: "FINALIZED",
    value: "FINALIZED",
    state: "success",
    textColor: "white",
    description: "Application is finalized"
  },
  {
    label: "PAID",
    value: "PAID",
    state: "success",
    textColor: "white",
    description: "Application is already paid"
  },
  {
    label: "RELEASED",
    value: "RELEASED",
    state: "success",
    textColor: "white",
    description: "Application is for release"
  },
  {
    label: "FOR PICK-UP",
    value: "FOR PICK-UP",
    state: "success",
    textColor: "white",
    description: "The requested application is already for pick-up"
  },
  {
    label: "APPROVED",
    value: "APPROVED",
    state: "success",
    textColor: "white",
    description: "The requested application is already approved"
  },
  {
    label: "PRINTED",
    value: "PRINTED",
    state: "success",
    textColor: "white",
    description: "The requested application is already printed"
  },
  {
    label: "FOR REFUND APPROVAL",
    value: "FOR REFUND APPROVAL",
    state: "danger",
    textColor: "white",
    description: "Application is for refund approval"
  },
  {
    label: "PROCESSING REFUND",
    value: "PROCESSING REFUND",
    state: "info",
    textColor: "dark",
    description: "Applicant currently processing refund request"
  },
  {
    label: "COMPUTED",
    value: "COMPUTED",
    state: "info",
    textColor: "white",
    description: "Applicant has been computed"
  },
  {
    label: "SYNCED",
    value: "SYNCED",
    state: "info",
    textColor: "white",
    description: "Applicant has been synced"
  },
  {
    label: "FOR REASSESSMENT",
    value: "FOR REASSESSMENT",
    state: "warning",
    textColor: "dark",
    description: "Application needs to be modified//updated"
  },
  {
    label: "EXPIRED SOA",
    value: "EXPIRED SOA",
    state: "danger",
    textColor: "white",
    description: "Generated SOA is no longer valid"
  },
  {
    label: "ONGOING",
    value: "ONGOING",
    state: "ongoing",
    textColor: "white",
    description: "Application is on going"
  },
  {
    label: "VERIFIED",
    value: "VERIFIED",
    state: "verified",
    textColor: "white",
    description: "Application is verified"
  },
  {
    label: "ASSESSED BY PLANNING DEPARTMENT",
    value: "ASSESSED BY PLANNING DEPARTMENT",
    state: "verified",
    textColor: "white",
    description: "Application is assessed by planning department"
  },
  {
    label: "ASSESSED BY ENGINEERING DEPARTMENT",
    value: "ASSESSED BY ENGINEERING DEPARTMENT",
    state: "verified",
    textColor: "white",
    description: "Application is assessed by engineering department"
  },
  {
    label: "FOR ACCOUNT CREATION",
    value: "FOR ACCOUNT CREATION",
    state: "pending",
    textColor: "white",
    description: "Application is for account creation"
  },
  {
    label: "ASSESSED BY BPLO",
    value: "ASSESSED BY BPLO",
    state: "verified",
    textColor: "white",
    description: "Application is assessed by BPLO"
  },
];

export const EXTENSION_NAMES = [
  {
    label: "None",
    value: ""
  },
  {
    label: "SR.",
    value: "SR."
  },
  {
    label: "JR.",
    value: "JR."
  },
  {
    label: "II",
    value: "II"
  },
  {
    label: "III",
    value: "III"
  },
  {
    label: "IV",
    value: "IV"
  },
  {
    label: "V",
    value: "V"
  },
  {
    label: "VI",
    value: "VI"
  },
  {
    label: "VII",
    value: "VII"
  },
  {
    label: "VIII",
    value: "VIII"
  },
  {
    label: "IX",
    value: "IX"
  },
  {
    label: "X",
    value: "X"
  }
];

export const CIVIL_STATUSES = [
  {
    label: "SINGLE",
    value: "SINGLE"
  },
  {
    label: "MARRIED",
    value: "MARRIED"
  },
  {
    label: "DIVORCED",
    value: "DIVORCED"
  },
  {
    label: "WIDOW",
    value: "WIDOW"
  },
  {
    label: "SEPARATED",
    value: "SEPARATED"
  },
  {
    label: "ANNULLED",
    value: "ANNULLED"
  },
  {
    label: "WIDOWER",
    value: "WIDOWER"
  },
  {
    label: "SINGLE PARENT",
    value: "SINGLE PARENT"
  }
];

export const GENDERS = [
  { label: "FEMALE", value: "FEMALE" },
  { label: "MALE", value: "MALE" }
];

// v2
export const GENDER_OPTION = [
  {
    label: "Male",
    value: "M"
  },
  {
    label: "Female",
    value: "F"
  }
];

export const VALID_IDS = [
  {
    label: "PASSPORT",
    value: "PASSPORT"
  },
  {
    label: "SSS",
    value: "SSS"
  },
  {
    label: "UMID",
    value: "UMID"
  },
  {
    label: "PHILHEALTH",
    value: "PHILHEALTH"
  },
  {
    label: "DRIVER's LICENSE",
    value: "DRIVER's LICENSE"
  },
  {
    label: "VOTER's ID",
    value: "VOTER's ID"
  },
  {
    label: "SENIOR CITIZEN's ID",
    value: "SENIOR CITIZEN's ID"
  },
  {
    label: "POSTAL ID",
    value: "POSTAL ID"
  },
  {
    label: "BARANGAY ID",
    value: "BARANGAY ID"
  },
  {
    label: "Authorization Letter",
    value: "Authorization Letter"
  }
];

export const PICKUP_DELIVERY = [
  { label: "FOR PICK-UP", value: "0" }
  // { label: "Delivery", value: "1" },
];

export const NATURE_OF_WORKS = [
  { label: "White Card (Food Handlers)", value: "WHITE_CARD" },
  { label: "Green Card (Non-food Handler)", value: "GREEN_CARD" },
  {
    label: "Pink Card (Entertainment Establishment Workers)",
    value: "PINK_CARD"
  }
];

export const WHAT_TO_PRINTS = [
  { label: "FRONT (P50)", value: "FRONT", amount: 50 },
  { label: "BACK (P50)", value: "BACK", amount: 50 },
  { label: "FRONT AND BACK (P100)", value: "FRONT AND BACK", amount: 100 }
];

export const WHAT_TO_PRINTS_SMART_CITY = [
  { label: "LCR FORM 1-A", value: "LCR FORM 1-A", amount: 100 },
];

export const YES_OR_NO = [
  { label: "YES", value: 1 },
  { label: "NO", value: 0 }
];

export const PURPOSES = [
  { label: "Claim Benefits/ Loan", value: "Claim Benefits/ Loan" },
  { label: "Passport / Travel", value: "Passport / Travel" },
  { label: "School Requirements", value: "School Requirements" },
  { label: "Employment Local", value: "Employment Local" },
  { label: "Employment Abroad", value: "Employment Abroad" },
  { label: "Others", value: "Others" }
];

export const OWNERSHIP = [
  { label: "Single Proprietorship", value: "Single Proprietorship" },
  { label: "Partnership", value: "Partnership" },
  { label: "Corporation", value: "Corporation" }
];

export const PROFILE_FORM = {
  is_resident: 0,
  first_name: "",
  middle_name: "",
  last_name: "",
  mobile_number: "",
  email: "",
  photo: "",
  region_code: "",
  region_name: "",
  province_code: "",
  province_name: "",
  municipality_code: "",
  municipality_name: "",
  barangay_code: "",
  barangay_name: "",
  street: "",
  gender: "",
  place_of_birth_region_code: "",
  place_of_birth_province_code: "",
  place_of_birth_municipality_code: "",
  place_of_birth_region_name: "",
  place_of_birth_province_name: "",
  place_of_birth_municipality_name: "",
  birth_date: "",
  civil_status: "",
  extension_name: "",
  mother_maiden_last_name: "",
  mother_maiden_middle_name: "",
  mother_maiden_first_name: "",
  spouse_last_name: "",
  spouse_middle_name: "",
  spouse_first_name: "",
  spouse_mobile_number: "",
  father_suffix_name: "",
  father_last_name: "",
  father_middle_name: "",
  father_first_name: "",
  telephone_number: "",
  height: "",
  weight: "",
  complexion: "",
  highest_educational_attainment: "",
  citizenship_code: "",
  citizenship_name: "",
  institution_code: "",
  institution_name: "",
  tin_number: "",
  sss_id_number: "",
  philhealth_id_number: "",
  gsis_id_number: "",
  pagibig_id_number: "",
  is_voter: 0,
  user_id: "",
  is_user_id_auth: 1,
  google2fa_enable: '',
  preferred_mfa: ''
};

export const HAIR_COLORS = [
  {
    label: "BLACK",
    value: "BLACK"
  },
  {
    label: "BROWN",
    value: "BROWN"
  },
  {
    label: "BLOND",
    value: "BLOND"
  },
  {
    label: "AUBURN",
    value: "AUBURN"
  },
  {
    label: "CHESTNUT",
    value: "CHESTNUT"
  },
  {
    label: "RED",
    value: "RED"
  },
  {
    label: "GREY AND WHITE",
    value: "GREY AND WHITE"
  }
];

export const EYES_COLORS = [
  {
    label: "AMBER",
    value: "AMBER"
  },
  {
    label: "BLACK",
    value: "BLACK"
  },
  {
    label: "BLUE",
    value: "BLUE"
  },
  {
    label: "BROWN",
    value: "BROWN"
  },
  {
    label: "GRAY",
    value: "GRAY"
  },
  {
    label: "GREEN",
    value: "GREEN"
  },
  {
    label: "HAZEL",
    value: "HAZEL"
  },
  {
    label: "HAZEL",
    value: "HAZEL"
  }
];

export const BLOOD_TYPES = [
  {
    label: "A",
    value: "A"
  },
  {
    label: "B",
    value: "B"
  },
  {
    label: "AB",
    value: "AB"
  },
  {
    label: "AB+",
    value: "AB+"
  },
  {
    label: "O",
    value: "O"
  }
];

export const DISTINGUISHING_MARKS = [
  {
    label: "NONE",
    value: "NONE"
  },
  {
    label: "MOLE",
    value: "MOLE"
  },
  {
    label: "SCAR",
    value: "SCAR"
  },
  {
    label: "TATTOO",
    value: "TATTOO"
  },
  {
    label: "BIRTHMARK",
    value: "BIRTHMARK"
  },
  {
    label: "HARELIP",
    value: "HARELIP"
  },
  {
    label: "SKINTAG",
    value: "SKINTAG"
  }
];

export const PURPOSE = [
  { label: "ADOPTION PURPOSE", value: "ADOPTION PURPOSE" },
  { label: "AFP ENLISTMENT", value: "AFP ENLISTMENT" },
  { label: "AFP REQUIREMENT", value: "AFP REQUIREMENT" },
  { label: "BANK REQUIREMENT", value: "BANK REQUIREMENT" },
  { label: "BFP REQUIREMENT", value: "BFP REQUIREMENT" },
  { label: "BUSINESS REQUIREMENT", value: "BUSINESS REQUIREMENT" },
  { label: "BJMP REQUIREMENT", value: "BJMP REQUIREMENT" },
  { label: "CHANGE OF FIRSTNAME", value: "CHANGE OF FIRSTNAME" },
  { label: "CHANGE OF GENDER", value: "CHANGE OF GENDER" },
  { label: "CHANGE OF MIDDLENAME", value: "CHANGE OF MIDDLENAME" },
  { label: "CHANGE OF SURNAME", value: "CHANGE OF SURNAME" },
  { label: "CIVIL SERVICE REQUIREMENT", value: "CIVIL SERVICE REQUIREMENT" },
  {
    label: "COURT CLEARANCE REQUIREMENT",
    value: "COURT CLEARANCE REQUIREMENT"
  },
  { label: "COMELEC REQUIREMENT", value: "COMELEC REQUIREMENT" },
  {
    label: "CORRECTION OF BIRTH CERTIFICATE",
    value: "CORRECTION OF BIRTH CERTIFICATE"
  },
  { label: "CORRECTION OF BIRTH DATE", value: "CORRECTION OF BIRTH DATE" },
  { label: "CORRECTION OF ENTRY", value: "CORRECTION OF ENTRY" },
  { label: "CORRECTION OF FIRST NAME", value: "CORRECTION OF FIRST NAME" },
  { label: "CORRECTION OF MIDDLE NAME", value: "CORRECTION OF MIDDLE NAME" },
  { label: "CORRECTION OF LAST NAME", value: "CORRECTION OF LAST NAME" },
  { label: "CSWD REQUIREMENT", value: "CSWD REQUIREMENT" },
  { label: "DRIVERS LICENSE", value: "DRIVERS LICENSE" },
  { label: "DSWD REQUIREMENT", value: "DSWD REQUIREMENT" },
  { label: "EMPLOYMENT ABROAD", value: "EMPLOYMENT ABROAD" },
  { label: "FIDELITY BOND", value: "FIDELITY BOND" },
  { label: "FIREARMS LICENSE", value: "FIREARMS LICENSE" },
  { label: "FIREARMS RENEWAL", value: "FIREARMS RENEWAL" },
  { label: "FIREARMS REQUIREMENT", value: "FIREARMS REQUIREMENT" },
  { label: "FOR ABROAD", value: "FOR ABROAD" },
  { label: "FOR BOARD EXAM", value: "FOR BOARD EXAM" },
  { label: "FOR FIREWORKS PERMIT", value: "FOR FIREWORKS PERMIT" },
  { label: "FOR PROVISION", value: "FOR PROVISION" },
  { label: "FOR REHABILITATION", value: "FOR REHABILITATION" },
  { label: "GENERAL PURPOSE", value: "GENERAL PURPOSE" },
  { label: "GSIS REQUIREMENT", value: "GSIS REQUIREMENT" },
  { label: "IDENTIFICATION", value: "IDENTIFICATION" },
  { label: "IMMIGRATION PURPOSE", value: "IMMIGRATION PURPOSE" },
  { label: "IMMERSION REQUIREMENT", value: "IMMERSION REQUIREMENT" },
  { label: "LEGAL INTENT", value: "LEGAL INTENT" },
  { label: "LEGAL PURPOSE", value: "LEGAL PURPOSE" },
  { label: "LOAN PURPOSE", value: "LOAN PURPOSE" },
  { label: "LOAN REQUIREMENT", value: "LOAN REQUIREMENT" },
  { label: "LOAN RETIREMENT", value: "LOAN RETIREMENT" },
  { label: "LOCAL EMPLOYMENT", value: "LOCAL EMPLOYMENT" },
  { label: "LOST ID REQUIREMENT", value: "LOST ID REQUIREMENT" },
  { label: "LTO REQUIREMENT", value: "LTO REQUIREMENT" },
  { label: "LTFRB REQUIREMENT", value: "LTFRB REQUIREMENT" },
  { label: "LTOPF", value: "LTOPF" },
  { label: "MARRIAGE REQUIREMENT", value: "MARRIAGE REQUIREMENT" },
  { label: "MAYOR's PERMIT REQUIREMENT", value: "MAYOR's PERMIT REQUIREMENT" },
  { label: "MAYNILAD REQUIREMENT", value: "MAYNILAD REQUIREMENT" },
  { label: "MERALCO REQUIREMENT", value: "MERALCO REQUIREMENT" },
  { label: "MOTORCYCLE FRANCHISE", value: "MOTORCYCLE FRANCHISE" },
  { label: "MIAA REQUIREMENT", value: "MIAA REQUIREMENT" },
  { label: "NHA REQUIREMENT", value: "NHA REQUIREMENT" },
  { label: "NATURALIZATION", value: "NATURALIZATION" },
  { label: "NBI REQUIREMENT", value: "NBI REQUIREMENT" },
  { label: "NSO REQUIREMENT", value: "NSO REQUIREMENT" },
  { label: "OJT PURPOSE", value: "OJT PURPOSE" },
  { label: "PAG IBIG REQUIREMENT", value: "PAG IBIG REQUIREMENT" },
  { label: "PASSPORT REQUIREMENT", value: "PASSPORT REQUIREMENT" },
  { label: "PHILHEALTH REQUIREMENT", value: "PHILHEALTH REQUIREMENT" },
  { label: "PNP APPLICATION", value: "PNP APPLICATION" },
  { label: "PNP REQUIREMENT", value: "PNP REQUIREMENT" },
  {
    label: "PHILIPPINE AIR FORCE REQUIREMENT",
    value: "PHILIPPINE AIR FORCE REQUIREMENT"
  },
  { label: "PRC REQUIREMENT", value: "PRC REQUIREMENT" },
  { label: "PTC PERMIT", value: "PTC PERMIT" },
  { label: "POSTAL ID REQUIREMENT", value: "POSTAL ID REQUIREMENT" },
  { label: "PROOF OF RESIDENCY", value: "PROOF OF RESIDENCY" },
  { label: "REFERENCE PURPOSE", value: "REFERENCE PURPOSE" },
  { label: "REMITTANCE PURPOSE", value: "REMITTANCE PURPOSE" },
  { label: "RETIREMENT", value: "RETIREMENT" },
  { label: "SECURITY LICENSE PURPOSE", value: "SECURITY LICENSE PURPOSE" },
  { label: "SSS REQUIREMENT", value: "SSS REQUIREMENT" },
  { label: "SCHOOL REQUIREMENT", value: "SCHOOL REQUIREMENT" },
  { label: "TERMINAL LEAVE", value: "TERMINAL LEAVE" },
  { label: "TESDA REQUIREMENT", value: "TESDA REQUIREMENT" },
  { label: "TRAVEL ABROAD", value: "TRAVEL ABROAD" },
  { label: "VERIFICATION", value: "VERIFICATION" },
  { label: "VISA PURPOSE", value: "VISA PURPOSE" },
  { label: "VISA REQUIREMENT", value: "VISA REQUIREMENT" },
  { label: "VISA SEAMAN", value: "VISA SEAMAN" },
  {
    label: "VISA SEAMANBOOK REQUIREMENT",
    value: "VISA SEAMANBOOK REQUIREMENT"
  },
  { label: "VISA SEAWOMAN", value: "VISA SEAWOMAN" },
  { label: "VISA USA", value: "VISA USA" },
  { label: "VEHICLE REQUIREMENT", value: "VEHICLE REQUIREMENT" },
  { label: "WEDDING PURPOSE ", value: "WEDDING PURPOSE " }
];

export const CATEGORY = [
  {
    label: "Health Worker",
    value: "Health Worker"
  },
  {
    label: "Seniors (Indigent)",
    value: "Seniors (Indigent)"
  },
  {
    label: "Seniors",
    value: "Seniors"
  },
  {
    label: "PWD",
    value: "PWD"
  },
  {
    label: "Indigent (Mahirap)",
    value: "Indigent (Mahirap)"
  },
  {
    label: "Non-Medical Frontliner",
    value: "Non-Medical Frontliner"
  },
  {
    label: "Uniform Personnel",
    value: "Uniform Personnel"
  },
  {
    label: "General Public",
    value: "General Public"
  },
  {
    label: "Others",
    value: "Others"
  }
];

export const EMPLOYMENT_STATUS = [
  {
    label: "Government Employee",
    value: "Government Employee"
  },
  {
    label: "Private Employee",
    value: "Private Employee"
  },
  {
    label: "Self-Employed",
    value: "Self-Employed"
  },
  {
    label: "Private Practitioner",
    value: "Private Practitioner"
  },
  {
    label: "Others",
    value: "Others"
  }
];

export const BARANGAY = {
  "SAN JUAN": [
    {
      label: "ADDITION HILLS",
      value: "ADDITION HILLS"
    },
    {
      label: "BALONG BATO",
      value: "BALONG BATO"
    },
    {
      label: "BATIS",
      value: "BATIS"
    },
    {
      label: "CORAZON DE JESUS",
      value: "CORAZON DE JESUS"
    },
    {
      label: "ERMITAÑO",
      value: "ERMITAÑO"
    },
    {
      label: "GREENHILLS",
      value: "GREENHILLS"
    },
    {
      label: "ISABELITA",
      value: "ISABELITA"
    },
    {
      label: "KABAYANAN",
      value: "KABAYANAN"
    },
    {
      label: "LITTLE BAGUIO",
      value: "LITTLE BAGUIO"
    },
    {
      label: "MAYTUNAS",
      value: "MAYTUNAS"
    },
    {
      label: "ONSE",
      value: "ONSE"
    },
    {
      label: "PASADENA",
      value: "PASADENA"
    },
    {
      label: "PEDRO CRUZ",
      value: "PEDRO CRUZ"
    },
    {
      label: "PROGRESO",
      value: "PROGRESO"
    },
    {
      label: "RIVERA",
      value: "RIVERA"
    },
    {
      label: "SALAPAN",
      value: "SALAPAN"
    },
    {
      label: "SAN PERFECTO",
      value: "SAN PERFECTO"
    },
    {
      label: "STA. LUCIA",
      value: "STA. LUCIA"
    },
    {
      label: "ST. JOSEPH",
      value: "ST. JOSEPH"
    },
    {
      label: "TIBAGAN",
      value: "TIBAGAN"
    },
    {
      label: "WEST CRAME",
      value: "WEST CRAME"
    }
  ],
  MANILA: [
    {
      label: "Barangay 1",
      value: "Barangay 1"
    },
    {
      label: "Barangay 2",
      value: "Barangay 2"
    },
    {
      label: "Barangay 3",
      value: "Barangay 3"
    },
    {
      label: "Barangay 4",
      value: "Barangay 4"
    },
    {
      label: "Barangay 5",
      value: "Barangay 5"
    }
  ]
};

export const VALID_IDS_FOR_COVID_VACCINE = [
  {
    label: "Driver's License",
    value: "Driver's License"
  },
  {
    label: "Passport",
    value: "Passport"
  },
  {
    label: "UMID",
    value: "UMID"
  },
  {
    label: "Indigent (Mahirap)",
    value: "Indigent (Mahirap)"
  },
  {
    label: "Senior Citizen ID",
    value: "Senior Citizen ID"
  },
  {
    label: "PWD ID",
    value: "PWD ID"
  },
  {
    label: "Company ID",
    value: "Company ID"
  },
  {
    label: "Student ID",
    value: "Student ID"
  },
  {
    label: "Others",
    value: "Others"
  }
];

export const INCIDENT_TYPES = [
  {
    label: "Injury to employee or contractor",
    value: "Injury to employee or contractor"
  },
  {
    label: "Quality Control",
    value: "Quality Control"
  },
  {
    label: "Liability Report",
    value: "Liability Report"
  },
  {
    label: "Property Damage",
    value: "Property Damage"
  },
  {
    label: "Security Incident",
    value: "Security Incident"
  },
  {
    label: "Environmental Incident",
    value: "Environmental Incident"
  },
  {
    label: "Lost time Injury",
    value: "Lost time Injury"
  },
  {
    label: "First-aid Treatment",
    value: "First-aid Treatment"
  },
  {
    label: "Death or Serious Incident",
    value: "Death or Serious Incident"
  },
  {
    label: "Others",
    value: "Others"
  }
];

export const STATUSES = [
  {
    label: "NEW",
    value: "NEW"
  },
  {
    label: "RENEWAL",
    value: "RENEWAL"
  }
];

export const ownershipOptions = [
  {
    label: "SOLE Proprietorship",
    value: "SOLE PROPRIETORSHIP",
  },
  {
    label: "One person Corporation",
    value: "ONE PERSON CORPORATION",
  },
  {
    label: "Partnership",
    value: "PARTNERSHIP",
  },
  {
    label: "Corporation",
    value: "CORPORATION",
  },
  {
    label: "Cooperative",
    value: "COOPERATIVE",
  },
];

export const mfaOptions = [
  {
    label: "Authenticator App",
    value: 'google_authenticator',
  },
  {
    label: "SMS Authentication",
    value: 'sms_otp',
  },
  {
    label: "Email Authentication",
    value: 'email_otp',
  }
];

export const NATURE_OF_WORKS_EDUC_INS = [
  { label: "Green Card (Non-food Handler)", value: "GREEN_CARD" }
];